.Toolbox-container, .button, .Toolbox-menu2 {
    position: absolute;
  }

.Toolbox-container {
    margin: auto;
    width: 400px;
    bottom: 10%;
    right: 50%;
    display: flex;
    justify-content: space-between;
    text-align: center;
}

.tuIcon {
    height:40px;
    width:auto;
    border-radius: 50%;
}

#toggle {
  display: none;
}

#toggle2 {
    display: none;
  }

.Toolbox-button {
  z-index: 999;
  width: 43px;
  height: 43px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.Toolbox-button:hover {
    text-shadow: 0px 2px 5px rgba(0,0,0,0.5);
}

.zooming:hover {
    -webkit-animation: zoom .5s both;
    animation: zoom .2s both;
}
.add-button {
}
.play-button {
    color: #2B6CBE;
    font-size: 3em;
}
.add-button:before {
    transform: rotate(90deg);
}

.close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -0px;
    top: -0px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #cfcece;
}

.Toolbox-button:active {
    animation: pulsate 1s ease-out;
    animation-iteration-count: 1;
}
@-webkit-keyframes pulsate {
}


.Toolbox-menu {
  /*display: none;*/
  /*  visibility:hidden;
  top: -340px;
  left: -110px;
    overflow-y: auto;
  transition: all 0.1s ease-in-out;
  background: #FFF;
  width: 300px;
    height: 350px;
  border-radius: 5px;
  box-shadow: 2px 3px 10px 0 rgba(0,0,0, 0.1);*/
    margin-top: 10px;
    padding: 10px;
}

.Toolbox-menu2 {
    /*display: none;*/
      visibility:hidden;
    top: -340px;
    left: -110px;
      overflow-y: auto;
    transition: all 0.1s ease-in-out;
    background: #FFF;
    width: 300px;
      height: 350px;
    border-radius: 5px;
    box-shadow: 2px 3px 10px 0 rgba(0,0,0, 0.1);
  }

/**
 * ----------------------------------------
 * animation jello-horizontal
 * ----------------------------------------
 */
@-webkit-keyframes jello-horizontal {
    0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
    30% {
        -webkit-transform: scale3d(1.25, 0.75, 1);
        transform: scale3d(1.25, 0.75, 1);
    }
    40% {
        -webkit-transform: scale3d(0.75, 1.25, 1);
        transform: scale3d(0.75, 1.25, 1);
    }
    50% {
        -webkit-transform: scale3d(1.15, 0.85, 1);
        transform: scale3d(1.15, 0.85, 1);
    }
    65% {
        -webkit-transform: scale3d(0.95, 1.05, 1);
        transform: scale3d(0.95, 1.05, 1);
    }
    75% {
        -webkit-transform: scale3d(1.05, 0.95, 1);
        transform: scale3d(1.05, 0.95, 1);
    }
    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}
@keyframes jello-horizontal {
    0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
    30% {
        -webkit-transform: scale3d(1.25, 0.75, 1);
        transform: scale3d(1.25, 0.75, 1);
    }
    40% {
        -webkit-transform: scale3d(0.75, 1.25, 1);
        transform: scale3d(0.75, 1.25, 1);
    }
    50% {
        -webkit-transform: scale3d(1.15, 0.85, 1);
        transform: scale3d(1.15, 0.85, 1);
    }
    65% {
        -webkit-transform: scale3d(0.95, 1.05, 1);
        transform: scale3d(0.95, 1.05, 1);
    }
    75% {
        -webkit-transform: scale3d(1.05, 0.95, 1);
        transform: scale3d(1.05, 0.95, 1);
    }
    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

.tu-item {
  text-align: justify-all;
  display: flex;
    justify-content: space-between;
  margin: 0;
  padding: 5px 5px;
  color: #2B6CBE;
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
    cursor: pointer;
}

.tu-item:hover {
    background-color: #2B6CBE;
    color: white;
}

/*#toggle:checked ~ .Toolbox-menu {
    display: flex;
    visibility: visible;
    -webkit-animation: jello-horizontal 0.4s both;
    animation: jello-horizontal 0.4s both;
}*/

#toggle2:checked ~ .Toolbox-menu2 {
    display: flex;
    visibility: visible;
    -webkit-animation: jello-horizontal 0.4s both;
    animation: jello-horizontal 0.4s both;
}

@keyframes tremble {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    100% {
        -webkit-transform: scale(1.3);
        transform: scale(1.3);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}

#toggle:checked ~ .add-button {
  transform: rotate(360deg);
  box-shadow: 0 0 0 0 transparent;
}

#toggle2:checked ~ .add-button {
    transform: rotate(360deg);
    box-shadow: 0 0 0 0 transparent;
  }

@keyframes zoom {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    100% {
        -webkit-transform: scale(1.3);
        transform: scale(1.3);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
.rotating {
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
}