
.client-item{
    display: flex;
    justify-content: space-between;
}

.client-item-name{
    width: 100%;
    height: 100%;
}

.scenario-item-cartouche-name{
    margin: 0;
    font-size: 1.2rem;
    margin-left: 1rem;
    margin-top: 0.5rem;
}