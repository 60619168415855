
.flux_title{
    display: flex;
    justify-content: space-around;
}

.client-name {
    color:#2B6CBE;
    padding-right: 1em;
}

.flux_title-item{
    margin: 0;
    font-size: 1.4rem;
    margin-left: 1rem;
    margin-top: 0.5rem;
}