.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 60px;
    box-shadow: 0 16px 38px -12px rgba(0,0,0,.56), 0 4px 25px 0 rgba(0,0,0,.5), 0 8px 10px -5px rgba(0,0,0,.5);
    overflow:hidden;
    -webkit-transition:width .05s linear;
    transition:width .05s linear;
    -webkit-transform:translateZ(0) scale(1,1);
    -webkit-transition-delay: 0.5s;
    z-index:1000;
}

.sidebar, .sidebar-wrapper {
    height:100%;
    transition-property: top, bottom, width;
    transition-duration: .2s, .2s, .35s;
    transition-timing-function: linear, linear, ease;
}

.sidebar-client[data-background-color=black] {
    background: #2B6CBE;
}

.sidebar-admin[data-background-color=black] {
    background: #607C8A;
}

.sidebar:hover {
    transform: translateZ(0);
    opacity: 1;
    width:250px;
    -webkit-transition-delay: 0s;
}
.sidebar a {
     transition: all .15s ease-in;
 }

.sidebar .sidebar-wrapper>.nav [data-toggle=collapse]~div>ul>li>a span, .sidebar .user .photo, .sidebar .user .user-info>a>span {
    transition: all .3s linear;
}

.sidebar[data-background-color=black] .nav .nav-item .nav-link {

}

.sidebar .sidebar-wrapper>.nav [data-toggle=collapse]~div>ul>li>a .sidebar-normal {
    margin: 0;
    position: relative;
    transform: translateX(0);
    opacity: 1;
    white-space: nowrap;
    display: block;
}

.sidebar .nav {
    margin-top: 15px;
    display: block;
}

@media (min-width: 991px) {
    .sidebar-mini .sidebar {
        display: block;
        font-weight: 200;
        z-index: 9999;
    }
}

@media (min-width: 991px) {
    .sidebar-mini .sidebar, .sidebar-mini .sidebar .sidebar-wrapper {
        width: 80px;
    }
}


.main-menu:hover,nav.main-menu.expanded {
    width:250px;
    overflow:visible;
}

.main-menu {
    background:#212121;
    border-right:1px solid #e5e5e5;
    position:absolute;
    top:0;
    bottom:0;
    height:100%;
    left:0;
    width:60px;
    overflow:hidden;
    -webkit-transition:width .05s linear;
    transition:width .05s linear;
    -webkit-transform:translateZ(0) scale(1,1);
    z-index:1000;
}

.main-menu>ul {
    margin:7px 0;
}

.main-menu li {
    position:relative;
    display:block;
    width:250px;
}

.main-menu li>a {
    position:relative;
    display:table;
    border-collapse:collapse;
    border-spacing:0;
    color:#999;
    font-family: arial;
    font-size: 14px;
    text-decoration:none;
    -webkit-transform:translateZ(0) scale(1,1);
    -webkit-transition:all .1s linear;
    transition:all .1s linear;

}

.main-menu .nav-icon {
    position:relative;
    display:table-cell;
    width:60px;
    height:36px;
    text-align:center;
    vertical-align:middle;
    font-size:18px;
}

.main-menu .nav-text {
    position:relative;
    display:table-cell;
    vertical-align:middle;
    width:190px;
    font-family: 'Titillium Web', sans-serif;
}

.main-menu>ul.logout {
    position:absolute;
    left:0;
    bottom:0;
}

.no-touch .scrollable.hover {
    overflow-y:hidden;
}

.no-touch .scrollable.hover:hover {
    overflow-y:auto;
    overflow:visible;
}

a:hover,a:focus {
    text-decoration:none;
}

nav {
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    -o-user-select:none;
    user-select:none;
}

nav ul,nav li {
    outline:0;
    margin:0;
    padding:0;
}
.main-menu li:hover>a,nav.main-menu li.active>a,.dropdown-menu>li>a:hover,.dropdown-menu>li>a:focus,.dropdown-menu>.active>a,.dropdown-menu>.active>a:hover,.dropdown-menu>.active>a:focus,.no-touch .dashboard-page nav.dashboard-menu ul li:hover a,.dashboard-page nav.dashboard-menu ul li.active a {
    color:#fff;
    background-color:#5fa2db;
}
.area {
    float: left;
    background: #e2e2e2;
    width: 100%;
    height: 100%;
}
@font-face {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 300;
    src: local('Titillium WebLight'), local('TitilliumWeb-Light'), url(https://themes.googleusercontent.com/static/fonts/titilliumweb/v2/anMUvcNT0H1YN4FII8wpr24bNCNEoFTpS2BTjF6FB5E.woff) format('woff');
}
