.chip{
    display: inline-flex;
    flex-direction: row;
    background-color: #e5e5e5;
    border: none;
    cursor: default;
    height: 45px;
    outline: none;
    padding: 0;
    font-size: 14px;
    font-color: #333333;
    font-family:"Open Sans", sans-serif;
    white-space: nowrap;
    align-items: center;
    border-radius: 20px;
    vertical-align: middle;
    text-decoration: none;
    justify-content: center;
}
.chip-icon {
    width: 45px;
    height: 45px;
    display: flex;
    overflow: hidden;
    position: relative;
    font-size: 1.25rem;
    /* align-items: center; */
    flex-shrink: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    /* line-height: 1; */
    user-select: none;
    border-radius: 50%;
    justify-content: center;
}

.chip-icon-img {
    color: transparent;
    width: 100%;
    height: 100%;
    object-fit: cover;
    text-align: center;
    text-indent: 10000px;
}
.chip-head{
    display: flex;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    align-items: center;
    user-select: none;
    border-radius: 50%;
    justify-content: center;
    height: 100%;
    width: auto;
    color: #fff;
    font-size: 20px;
    margin-right: -4px;
}
.chip-content{
    cursor: inherit;
    display: flex;
    align-items: center;
    user-select: none;
    white-space: nowrap;
    padding-left: 0.5em;
    padding-right: 0.2em;
}
.chip-svg{
    color: #999999;
    cursor: pointer;
    margin: 4px 4px 0 -8px;
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 24px;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    user-select: none;
    flex-shrink: 0;
}
.chip-svg:hover{
    color: #666666;
}