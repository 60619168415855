.Logo {
    height: 50px;
    margin-top: 1rem;
    padding: 0.5rem 0.8rem;
}

.Logo img {
    height: 100%;
    transition: all .15s ease-in;
}

.Logo img:hover {
    transform: rotate(45deg);

}