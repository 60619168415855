.custom-node{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.custom-node-container {
    width: 100px;
}

.cn-name {
    width:120px;
    color: #333333;
    text-align: center;
    top: 110px;
    left: 0px;
}

.custom-node-image{
    border-radius: 50%;
    width: 100px;
    height: 100px;
    cursor: grab;
    background-color: ghostwhite;
    background-origin: border-box;
    background-clip: border-box;
    background: center/100%;
    background-position: 0 0;
    background-repeat: no-repeat;
    box-shadow: 0 0px 25px -12px rgba(0,0,0,.56), 0 4px 25px 0 rgba(0,0,0,.5), 0 8px 10px -5px rgba(0,0,0,.5);
    transition: all .15s ease-in;
}


.custom-node-image:hover {
    -webkit-animation: heartbeat .5s ease-in-out infinite both;
    animation: heartbeat .5s ease-in-out infinite both;

}

.material-icons {
    vertical-align: middle !important;
    padding-bottom: 3px;
}


@keyframes heartbeat {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    50% {
        -webkit-transform: scale(0.91);
        transform: scale(0.91);
        box-shadow: none;
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}

.custom-node-color{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    transform: translate(-50%, -50%);
    border-radius: 100px;
}

.circle-port{
    width: 12px;
    height: 12px;
    margin: 0px;
    border-radius: 50%;
    background: #2B6CBE;
    cursor: pointer;
}

.circle-port:hover{
    background: mediumpurple;
}


.ofc-node {
    display: block;
    width: 60px;
    height: 60px;
}

.custom-node-image:hover + .node-toolbox {
    display:block;
}

.node-toolbox {
    display:none;
    position: absolute;
    top: -15%;
    right: 20%;
    border-radius: 40%;
}

.node-toolbox:hover {
    display:block;
}


.node-toolbox-button {
    padding: 0;
    color:white;
    border-radius: 40%;
    transition: all .15s ease-in;
    background-color: #2B6CBE;
}

.btn-group-sm>.btn {
    padding: .30rem .2rem 0rem .2rem;
    font-size: 1.1rem;
    border-radius: 40%;
}

.node-toolbox-button i {
    font-size: inherit;
}

.edit-button {
    width: 24px;
    height: 27px;
    background-color: white;
    border-radius: 20%;
    padding: auto;
    cursor: pointer;
    transition: all .15s ease-in;
}
.edit-button i {
    color: #333333;
}

.edit-button i:hover {
    color: white;
}

.edit-button:hover {
    background-color: #2B6CBE;
    color: white;
}

.order-info {
    margin-top: auto;
    margin-bottom: auto;
    border: solid 1px  grey;
    border-radius: 10%;
    color: dimgrey;
    padding:0px;
    font-size: 0.8em;
    height: 20px;
    width: 20px;
    text-align: center;
}

.order-info-label {
}

