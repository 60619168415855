.scenarios {
    height: 100%;
    overflow: auto;
}

.regular-page {    
    overflow: auto;
}

.client-button:hover {
    color: whitesmoke;
    background-color: #607C8A;
}