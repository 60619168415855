.parameter-sidebar {
    background-color: lightgrey;
}
.w3-sidebar {
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
    width:30%;
    top: 0;
    right:0;
    height: 100%;
    position: fixed;
    z-index: 1000;
    overflow: auto;
    transition:1s right;
    -webkit-transition:width .05s linear;
    transition-duration: .2s, .2s, .35s;
}

.w3-bar-block .w3-bar-item {
    width: 100%;
    display: block;
    padding: 8px 16px;
    text-align: left;
    border: none;
    white-space: normal;
    float: none;
    outline: 0;
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Segoe UI",Arial,sans-serif;
    font-weight: 400;
    margin: 10px 0;
}

.tu-param-title {
    font-size: 1.5em;
    vertical-align:middle;
    padding-bottom: 0;
    margin-bottom: 0;
}

.tu-param-icon {
    font-size: 1em;
    vertical-align:-4px;
}

.tu-param-name {
    padding-left: 2em;
    padding-bottom:0.1em;
    color: ghostwhite;
}

.w3-bar-block .w3-bar-item {
    width: 100%;
    display: block;
    padding: 8px 16px;
    text-align: left;
    border: none;
    white-space: normal;
    float: none;
    outline: 0;
}

.param-header {
    margin:0;
    padding: 8px;
    width: 100%;
    color: whitesmoke;
}

.w3-btn, .w3-button {
    border: none;
    display: inline-block;
    padding: 8px 16px;
    vertical-align: middle;
    overflow: hidden;
    text-decoration: none;
    color: inherit;
    background-color: inherit;
    text-align: center;
    cursor: pointer;
    white-space: nowrap;
}

.w3-btn.w3-disabled:hover,.w3-btn:disabled:hover{box-shadow:none}

/* close icon styles */
.nav__close {
    position: absolute;
    top: 30px;
    right: 30px;
    width: 40px;
    height: 25px;
    cursor: pointer;
    /* close icon elements */
    /* hover effect */
}
.nav__close:before, .nav__close:after {
    -webkit-transition-property: all;
    transition-property: all;
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
    display: block;
    width: 4px;
    height: 28px;
    border-radius: 10px;
    content: "";
    -webkit-transform-origin: center center;
    transform-origin: center center;
    background-color: whitesmoke;
}
.nav__close:before {
    position: absolute;
    top: 0;
    left: 18px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.nav__close:after {
    position: absolute;
    top: 0;
    right: 18px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
.nav__close:hover:before, .nav__close:hover:after {
    background-color: #2B6CBE;
}

/* FORM CSS */

.rjsf{
    margin: 10px;
}

#root {
    background-color: inherit;
}

/*
TU Parameters Themes
 */
.oauthhttp {
    background-color: #FBC02D;
}

.createdata {
    background-color: #00796B;
}

.setvariable {
    background-color: #388E3C;
}

.getvariable {
    background-color: #388E3C;
}

.createjson {
    background-color: #1A237E;
}

.parsejson {
    background-color: #1A237E;
}

.parsexml {
    background-color: #FF7043;
}

.createxml {
    background-color: #FF7043;
}

.createtxt {
    background-color: #8D6E63;
}

.parsecsv {
    background-color: #64DD17;
}

.foreach {
    background-color: #424242;
}
.httpsimple {
    background-color: #FBC02D;
}

.array {
    background-color: #2962FF;
}

.articleshopify {
    background-color: #95BF47;
}

.entityxrpflex {
    background-color: #2962FF;
}

.mysql {
    background-color: #E65100;
}