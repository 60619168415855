.custom-node{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.custom-super-node-container {
    width: 300px;
    background-color: lightgrey;
    border-radius: 40px 40px 15px 15px;
    border-color: #333333;
    box-shadow: 0 0px 25px -12px rgba(0,0,0,.56), 0 4px 25px 0 rgba(0,0,0,.5), 0 8px 10px -5px rgba(0,0,0,.5);
    padding-bottom: 10px;
}

.super-node-header {
    position: relative;
    width: 100%;
    padding-right: 25px;
    margin-bottom: 8px;
    background-color: gray;
    border-radius: 35px 35px 35px 35px;
    color: white;
    display: flex;
    justify-content: space-between;
    z-index: 10;
}

.super-node-body {
    width: 100%;
}

.cn-name {
    width:120px;
    color: #333333;
    text-align: center;
    top: 110px;
    left: 40px;
}

.custom-super-node-image{
    border-radius: 50%;
    margin: 4px;
    width: 60px;
    height: 60px;
    cursor: grab;
    background-color: dimgrey;
    background-origin: border-box;
    background-clip: border-box;
    background: center/100%;
    background-position: 0 0;
    background-repeat: no-repeat;
    transition: all .15s ease-in;
}

.sn-children-title-block {
    display:flex;
}

.super-node-children {
    display: flex;
    justify-content: space-between;
    background-color: white;
    margin-top: 4px;
    margin-left: 5px;
    margin-right: 5px;
    padding-left: 10px;
    padding-right: 0px;
    border-radius: 5px 5px 5px 5px;
    transition: all .15s ease-in;
}
.super-node-children:hover {
    background-color: grey;
    color : white;
}

.sn-children-title {
    margin-top: 2px;
    margin-left: 4px;
}

.sn-tuIcon{
    border-radius: 50% 0% 0% 50%;
    margin-top: 4px;
    margin-bottom: 4px;
    width: 25px;
    height: 25px;
}


.custom-super-node-image:hover {
    -webkit-animation: heartbeat .5s ease-in-out infinite both;
    animation: heartbeat .5s ease-in-out infinite both;

}


@keyframes heartbeat {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    50% {
        -webkit-transform: scale(0.91);
        transform: scale(0.91);
        box-shadow: none;
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}

.custom-node-color{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    transform: translate(-50%, -50%);
    border-radius: 100px;
}

.circle-port{
    width: 12px;
    height: 12px;
    margin: 0px;
    border-radius: 50%;
    background: #2B6CBE;
    cursor: pointer;
}

.circle-port:hover{
    background: mediumpurple;
}

.ofc-node {
    display: block;
    width: 60px;
    height: 60px;
}

.super-node-children:hover > .super-node-children-toolbox {
    display:block;
}

.material-icons {
    vertical-align: 1px !important;
    padding-bottom: 3px;
}

.super-node-children-toolbox {
    display:none;
    margin-top: 1px;
    position: absolute;
    right: 15%;
    border-radius: 40%;
}

.super-node-children-toolbox:hover {
    display:block;
}

.sn-children-button {
    background-color: dimgrey;
    color: white;
    font-size: 1rem;
}

.sn-children-button > .material-icons {
    font-size: 1rem;
    padding-top: 0;
}


.super-node-toolbox-button {
    padding: 0;
    color:white;
    transition: all .15s ease-in;
    background-color: transparent;
    border-color: transparent;
    border-radius: 0;
}

.btn-group-sm>.btn {
    padding: .30rem .2rem 0rem .2rem;
    font-size: 1rem;
}

.super-node-toolbox-button i {
    font-size: inherit;
}

.edit-button {
    width: 24px;
    height: 27px;
    background-color: white;
    border-radius: 20%;
    padding: auto;
    cursor: pointer;
    transition: all .15s ease-in;
}
.edit-button i {
    color: #333333;
}

.edit-button i:hover {
    color: white;
}


.edit-button:hover {
    background-color: #2B6CBE;
    color: white;
}

.order-info {
    margin-top: auto;
    margin-bottom: auto;
    border: solid 1px  grey;
    border-radius: 10%;
    color: dimgrey;
    padding:0px;
    font-size: 0.8em;
    height: 20px;
    width: 20px;
    text-align: center;
}

.order-info-label {
}

.sn-legend {
    margin-top: 10px;
    margin-left: 100px;
}

