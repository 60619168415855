.scenarios {
    height: 100%;
    margin-top: 1rem;
    margin-left: 280px;
    overflow: auto;
}

.list-group-item {
    padding: .75rem;
}

.scenario-item{
    display: flex;
    justify-content: space-between;
}

.scenarios-navbar {
    background-color: #2B6CBE;
    height: 83px;
    z-index: 200;
}

.scenarios-navbar-brand {
    margin-left: 60px;
    font-size: 2.5rem;
}

.scenario-item-cartouche{
    display: flex;
    width: 100%;
    height: 100%;
}
.scenario-item-cartouche-name{
    margin: 0;
    font-size: 1.2rem;
    margin-left: 1rem;
    margin-top: 0.5rem;

    width: 100%;
    height: 100%;
}