*{
	margin: 0;
	padding: 0;
}

html, body, #root {
	height: 100%;
	font-family: 'Open Sans', sans-serif;
	background-color: #e9ecef;
}

#root {
	overflow: hidden;
}

.ofc-diagram{
	background: ghostwhite;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.cursor{
	cursor: pointer;
}

.nomflux {
	background: ghostwhite;
	margin-bottom: 0px;
	padding-left: 80px;
	padding-top: 2px;
	font-size: 20px;
	font-weight: bold;
  }

::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar
{
	width: 6px;
	background-color: white;
}

::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #2B6CBE;
}