.sidebar .nav li a {
    border-radius: 3px;
    padding-left: 10px;
    padding-right: 10px;
    text-transform: capitalize;
    font-size: 13px;
    padding: 10px 15px;
    -webkit-transition-duration: 0.3s;
    color: #fff;
}
.sidebar-admin .nav li a:hover {
    color: #607C8A;
    font-weight: bold;
}
.sidebar-client .nav li a:hover {
    color: #2B6CBE;
    font-weight: bold;
}

.li {
    display: list-item;
    text-align: -webkit-match-parent;
}
.sidebar .nav p {
    margin: 0;
    line-height: 30px;
    font-size: 14px;
    position: relative;
    display: block;
    height: auto;
    white-space: nowrap;
    color: inherit;
}

.sidebar .nav p:hover {
    display: block;
}


.sidebar .nav i {
    font-size: 24px;
    float: left;
    margin-right: 15px;
    line-height: 30px;
    width: 30px;
    text-align: center;
    color: inherit;
}

a .material-icons {
    vertical-align: middle;
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
    color: inherit;
}

.nav-link {
    border: 1px solid transparent;
    border-radius: 0.5rem;
    flex: 1 1 auto;
    text-align: center;
}

.nav-link:hover,
.nav-link:focus {
    border-color: whitesmoke;
    margin:1rem;
    background-color: rgba(255, 255, 255, 0.6);
}

.nav-tabs .nav-link.disabled {
    color: #6c757d;
    background-color: transparent;
    border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fafafa;
    border-color: #dee2e6 #dee2e6 #fafafa;
}